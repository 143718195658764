import { Carousel } from 'antd'
import styled from 'styled-components'
export const Styled = styled(Carousel)`
  height: 0px;
  .slick-slide {
    li {
      display: table;
      color: #ffffff !important;
      border: none;
      border-radius: 15px;
      background-color: #f99b00 !important;
      margin: 0 5px;
      padding: 0 !important;
      height: 81px;
      width: 160px;
      white-space: normal;
      word-break: break-word;
      line-height: 1.2;
      text-align: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-blend-mode: overlay;
      overflow: hidden;
      text-overflow: ellipsis;
      div {
        display: inline-block;
        text-align: center;
        &.label {
          position: relative;
          height: 81px;
          width: 160px;
          background-color: #f99b00cf;
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    li:hover,
    li.selected {
      border: none !important;
      background-color: #92341b !important;
      color: #fff !important;
      cursor: pointer;

      div {
        &.label {
          background-color: #92341bcf !important;
        }
      }
    }
  }
  .slick-prev {
    margin-left: -10px;
  }
  .slick-next {
    margin-right: -10px;
  }
  .slick-prev,
  .slick-next {
    background-color: #ffffff;
    color: #f99b00;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    opacity: 0.7;
  }
  .slick-prev {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .slick-next,
  .slick-prev {
    top: 35px;
    &:before,
    &:after {
      border-right: 2px solid;
      content: '';
      display: block;
      height: 8px;
      margin-top: -6px;
      position: absolute;
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      right: 10px;
      top: 50%;
      width: 0;
    }
    &:after {
      margin-top: -1px;
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:hover,
    &:focus,
    &:hover:before,
    &:hover:after,
    &:focus:before,
    &:focus:after {
      background-color: #ffffff;
      color: #f99b00;
      opacity: 1;
    }
  }
  .slick-dots {
    top: 100px;
    z-index: 2;
  }
  @media only screen and (max-width: 768px) {
    .slick-slide {
      li {
        width: 120px;
        img,
        div {
          display: block;
          margin: 0 auto;
        }
      }
    }
    /* .slick-next, .slick-prev {
            display: none !important;
        } */
  }

  @media only screen and (max-width: 576px) {
    .slick-next {
        margin-right: 19px;
      }
      .slick-prev {
        margin-left: 0 !important;
        left: 0 !important;
        z-index: 3;
      }
  }
`
